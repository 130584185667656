import React from 'react';
import { Link } from 'gatsby';
import './header.scss';
import logo from '../../images/logo.png';

const links = [
  {
    to: '/',
    text: 'Accueil'
  },
  /*{
    to: '/blog',
    text: 'News'
  },*/
  {
    to: '/about',
    text: 'À propos'
  },
  {
    to: '/contact',
    text: 'Contact'
  }
];
const Header = ({ siteTitle }) => (
  <nav className="header border-secondary border-bottom bg-primary text-white fixed-top">
    <div className="container">
      <div className="d-flex flex-column flex-md-row">
        <Link to="/" className="p-3 text-center">
          <img src={logo} className="d-inline-block align-top mr-2" alt="Papillon bleu logo" />
          <span className="align-middle font-weight-bold text-white">{siteTitle}</span>
        </Link>
        <ul className="list-unstyled d-flex justify-content-between ml-md-auto align-self-md-center mb-md-0">
          {links.map(link => (
            <li key={link.to}>
              <Link
                to={link.to}
                exact="true"
                activeClassName="active"
                className="p-3 text-white text-uppercase text-truncate"
              >
                {link.text}
              </Link>
            </li>
          ))}
        </ul>
        <div className="d-none d-md-block pl-5 align-self-md-center">
          <button className="btn btn-outline-secondary">
            <Link className="text-light" to="/contact">
              Faire un don
            </Link>
          </button>
        </div>
      </div>
    </div>
  </nav>
);

export default Header;
