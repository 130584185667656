import React from 'react';

const Footer = () => (
  <div className="container">
    <div className="row justify-content-center px-3 py-5">
      <div className="col-12 col-md-6">
        <h3>À propos</h3>
        <p>Notre vision est de faire du Centre Papillon Bleu la structure qui répond le plus aux besoins des enfants
          déficients mentaux et handicapés moteurs au Sénégal.</p>
      </div>
      <div className="col-12 col-md-6">
        <h3>Quelques chiffres</h3>
        <ul className="list-unstyled">
          <li><strong>2</strong> centres au Sénégal</li>
          <li><strong>256</strong> enfants pris en charge</li>
          <li><strong>8</strong> enseignants</li>
        </ul>
      </div>
    </div>
    <div className="d-flex justify-content-center text-center border-top p-3">
      Made with ❤ by <a className="px-1" href="https://www.amadousall.com">Amadou Sall</a>
    </div>
  </div>
);

export default Footer;
