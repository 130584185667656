import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { graphql, StaticQuery } from 'gatsby';

import Header from '../header/header';
import '../../scss/index.scss';
import './layout.scss';
import Footer from '../footer/footer';
import imageUrl from '../../images/papillon-bleu-social-card.png';

const Layout = ({ children, data }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Site web du centre Papillon Bleu' },
            { name: 'keywords', content: 'social, handicap, enfant' },
            // Open Graph data
            { property: 'og:title', content: 'Papillon Bleu' },
            { property: 'og:site_name', content: 'Papillon Bleu' },
            { property: 'og:type', content: 'website' },
            { property: 'og:url', content: 'https://www.centrepapillonbleu.com/' },
            { property: 'og:description', content: `Tous pour l'éducation inclusive` },
            { property: 'og:image', content: imageUrl },
            // Twitter Card data
            { property: 'twitter:card', content: 'summary_large_image' },
            { property: 'twitter:title', content: 'Papillon Bleu' },
            { property: 'twitter:site', content: '@ahasall' },
            { property: 'twitter:creator', content: '@ahasall' },
            { property: 'twitter:url', content: 'https://www.centrepapillonbleu.com/' },
            { property: 'twitter:description', content: `Tous pour l'éducation inclusive` },
            { property: 'twitter:image:src', content: imageUrl }
          ]}
        >
          <html lang="en"/>
        </Helmet>
        <header className="mb-md-5 header">
          <Header siteTitle={data.site.siteMetadata.title}/>
        </header>
        <div>{children}</div>
        <footer className="footer mt-auto">
          <Footer/>
        </footer>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
